import { PatientInterface } from '../types/Patient'
import { FieldMapping, resolveFieldMapping } from '../utils/fieldMappingHelpers'

export const getPatient = (values: any) => {
  return (values as any).patient as PatientInterface
}

const patientFieldMap: FieldMapping[] = [
  { atnKey: 'patient_name', formKeys: ['first_name', 'last_name'] },
  { atnKey: 'patient_vorname', formKeys: ['first_name'] },
  { atnKey: 'patient_nachname', formKeys: ['last_name'] },
  { atnKey: 'patient_geburtsdatum', formKeys: ['date_of_birth'] },
  { atnKey: 'patient_email', formKeys: ['email'] },
]

const unusedPlaceholders = [
  'patient_anrede',
  'patient_titel',
  'patient_anschrift',
  'patient_adresse_1',
  'patient_adresse_2',
  'patient_telefon_privat',
  'patient_telefon_2',
  'patient_telefon_mobil',
  'patient_telefon_arbeit',
  'patient_nummer',
  'patient_arbeitgeber',
  'patient_arbeitgebertelefon',
  'patient_beruf',
  'patient_geburtsname',
  'patient_geburtsort',
  'patient_pflegegrad',
  'versicherter_anrede',
  'versicherter_titel',
  'versicherter_vorname',
  'versicherter_nachname',
  'versicherter_geburtsdatum',
  'versicherter_anschrift',
  'versicherter_land',
  'versicherter_telefon',
]

export const replacePatientContent = (
  content: string,
  patient: PatientInterface,
  activeLang: string,
) => {
  for (let placeholder of unusedPlaceholders) {
    content = content.replace(`{{${placeholder}}}`, '')
  }
  for (let mapping of patientFieldMap) {
    if (mapping.atnKey === 'patient_geburtsdatum') {
      const dob = new Date(patient.date_of_birth).toLocaleDateString(activeLang)
      content = content.replace(`{{${mapping.atnKey}}}`, dob)
    } else {
      content = content.replace(
        `{{${mapping.atnKey}}}`,
        resolveFieldMapping(patient, mapping.formKeys),
      )
    }
  }
  return content
}

import messages_de from './de.json'
import messages_en from './en.json'
import messages_ar from './ar.json'
import messages_tr from './tr.json'
import messages_fr from './fr.json'
import messages_es from './es.json'
import messages_ru from './ru.json'
import messages_uk from './uk.json'

export interface Language {
  key: string
  label: string
  messages: Record<string, string>
}

export const uiLanguages = ['de', 'en', 'ar', 'tr', 'fr', 'es', 'ru', 'uk']

export const languages = [
  { key: 'de', label: 'Deutsch', messages: messages_de },
  { key: 'en', label: 'English', messages: messages_en },
  { key: 'ar', label: 'العربية', messages: messages_ar },
  { key: 'tr', label: 'Türkçe', messages: messages_tr },
  { key: 'fr', label: 'Français', messages: messages_fr },
  { key: 'es', label: 'Español', messages: messages_es },
  { key: 'ru', label: 'Русский', messages: messages_ru },
  { key: 'uk', label: 'Українська', messages: messages_uk },
  // laungauges not supported by AAH interface
  { key: 'pl', label: 'Polski', messages: messages_de },
  { key: 'ch', label: '中文', messages: messages_de },
  { key: 'ja', label: '日本人', messages: messages_de },
  { key: 'aa', label: 'Afaraf', messages: messages_de },
  { key: 'ab', label: 'аҧсуа бызшәа', messages: messages_de },
  { key: 'ae', label: 'Avesta', messages: messages_de },
  { key: 'af', label: 'Afrikaans', messages: messages_de },
  { key: 'ak', label: 'Akan', messages: messages_de },
  { key: 'am', label: 'አማርኛ', messages: messages_de },
  { key: 'an', label: 'Aragonés', messages: messages_de },
  { key: 'as', label: 'অসমীয়া', messages: messages_de },
  { key: 'av', label: 'авар мацӀ', messages: messages_de },
  { key: 'ay', label: 'Aymar Aru', messages: messages_de },
  { key: 'az', label: 'Azərbaycan Dili', messages: messages_de },
  { key: 'ba', label: 'башҡорт теле', messages: messages_de },
  { key: 'be', label: 'беларуская мова', messages: messages_de },
  { key: 'bg', label: 'български език', messages: messages_de },
  { key: 'bi', label: 'Bislama', messages: messages_de },
  { key: 'bm', label: 'Bamanankan', messages: messages_de },
  { key: 'bn', label: 'বাংলা', messages: messages_de },
  { key: 'bo', label: 'བོད་ཡིག', messages: messages_de },
  { key: 'br', label: 'Brezhoneg', messages: messages_de },
  { key: 'bs', label: 'Bosanski Jezik', messages: messages_de },
  { key: 'ca', label: 'Català', messages: messages_de },
  { key: 'ce', label: 'нохчийн мотт', messages: messages_de },
  { key: 'co', label: 'Corsu', messages: messages_de },
  { key: 'cr', label: 'ᓀᐦᐃᔭᐍᐏᐣ', messages: messages_de },
  { key: 'cs', label: 'čeština', messages: messages_de },
  { key: 'cu', label: 'ѩзыкъ словѣньскъ', messages: messages_de },
  { key: 'cv', label: 'чӑваш чӗлхи', messages: messages_de },
  { key: 'cy', label: 'Cymraeg', messages: messages_de },
  { key: 'da', label: 'Dansk', messages: messages_de },
  { key: 'dv', label: 'ދިވެހި', messages: messages_de },
  { key: 'dz', label: 'རྫོང་ཁ', messages: messages_de },
  { key: 'ee', label: 'Eʋegbe', messages: messages_de },
  { key: 'el', label: 'Ελληνικά', messages: messages_de },
  { key: 'eo', label: 'Esperanto', messages: messages_de },
  { key: 'et', label: 'eesti', messages: messages_de },
  { key: 'eu', label: 'Euskara', messages: messages_de },
  { key: 'fa', label: 'فارسی', messages: messages_de },
  { key: 'ff', label: 'Fulfulde', messages: messages_de },
  { key: 'fi', label: 'Suomi', messages: messages_de },
  { key: 'fj', label: 'Vosa Vakaviti', messages: messages_de },
  { key: 'fo', label: 'Føroyskt', messages: messages_de },
  { key: 'fy', label: 'Frysk', messages: messages_de },
  { key: 'ga', label: 'Gaeilge', messages: messages_de },
  { key: 'gd', label: 'Gàidhlig', messages: messages_de },
  { key: 'gl', label: 'Galego', messages: messages_de },
  { key: 'gn', label: "Avañe'ẽ", messages: messages_de },
  { key: 'gu', label: 'ગુજરાતી', messages: messages_de },
  { key: 'gv', label: 'Gaelg', messages: messages_de },
  { key: 'ha', label: 'هَوُسَ', messages: messages_de },
  { key: 'he', label: 'עברית', messages: messages_de },
  { key: 'hi', label: 'हिन्दी', messages: messages_de },
  { key: 'ho', label: 'Hiri Motu', messages: messages_de },
  { key: 'hr', label: 'Hrvatski', messages: messages_de },
  { key: 'ht', label: 'Kreyòl ayisyen', messages: messages_de },
  { key: 'hu', label: 'Magyar', messages: messages_de },
  { key: 'hy', label: 'Հայերեն', messages: messages_de },
  { key: 'hz', label: 'Otjiherero', messages: messages_de },
  { key: 'ia', label: 'Interlingua', messages: messages_de },
  { key: 'id', label: 'Bahasa Indonesia', messages: messages_de },
  { key: 'ie', label: 'Interlingue', messages: messages_de },
  { key: 'ig', label: 'Asụsụ Igbo', messages: messages_de },
  { key: 'ii', label: 'ꆈꌠ꒿ Nuosuhxop', messages: messages_de },
  { key: 'ik', label: 'Iñupiaq', messages: messages_de },
  { key: 'io', label: 'Ido', messages: messages_de },
  { key: 'is', label: 'Íslenska', messages: messages_de },
  { key: 'it', label: 'Italiano', messages: messages_de },
  { key: 'iu', label: 'ᐃᓄᒃᑎᑐᑦ', messages: messages_de },
  { key: 'jv', label: 'Basa Jawa', messages: messages_de },
  { key: 'ka', label: 'ქართული', messages: messages_de },
  { key: 'kg', label: 'Kikongo', messages: messages_de },
  { key: 'ki', label: 'Gĩkũyũ', messages: messages_de },
  { key: 'kj', label: 'Kuanyama', messages: messages_de },
  { key: 'kk', label: 'қазақ тілі', messages: messages_de },
  { key: 'kl', label: 'Kalaallisut', messages: messages_de },
  { key: 'km', label: 'ខេមរភាសា', messages: messages_de },
  { key: 'kn', label: 'ಕನ್ನಡ', messages: messages_de },
  { key: 'ko', label: '한국어', messages: messages_de },
  { key: 'kr', label: 'Kanuri', messages: messages_de },
  { key: 'ks', label: 'कश्मीरी', messages: messages_de },
  { key: 'ku', label: 'Kurdî', messages: messages_de },
  { key: 'kv', label: 'коми кыв', messages: messages_de },
  { key: 'kw', label: 'Kernewek', messages: messages_de },
  { key: 'ky', label: 'Кыргызча', messages: messages_de },
  { key: 'la', label: 'Latine', messages: messages_de },
  { key: 'lb', label: 'Lëtzebuergesch', messages: messages_de },
  { key: 'lg', label: 'Luganda', messages: messages_de },
  { key: 'li', label: 'Limburgs', messages: messages_de },
  { key: 'ln', label: 'Lingála', messages: messages_de },
  { key: 'lo', label: 'ພາສາລາວ', messages: messages_de },
  { key: 'lt', label: 'Lietuvių Kalba', messages: messages_de },
  { key: 'lu', label: 'Kiluba', messages: messages_de },
  { key: 'lv', label: 'Latviešu Valoda', messages: messages_de },
  { key: 'mg', label: 'Fiteny Malagasy', messages: messages_de },
  { key: 'mh', label: 'Kajin M̧ajeļ', messages: messages_de },
  { key: 'mi', label: 'te reo Māori', messages: messages_de },
  { key: 'mk', label: 'македонски јазик', messages: messages_de },
  { key: 'ml', label: 'മലയാളം', messages: messages_de },
  { key: 'mn', label: 'Монгол хэл', messages: messages_de },
  { key: 'mr', label: 'मराठी', messages: messages_de },
  { key: 'ms', label: 'Bahasa Melayu', messages: messages_de },
  { key: 'mt', label: 'Malti', messages: messages_de },
  { key: 'my', label: 'ဗမာစာ', messages: messages_de },
  { key: 'na', label: 'Dorerin Naoero', messages: messages_de },
  { key: 'nb', label: 'Norsk bokmål', messages: messages_de },
  { key: 'nd', label: 'isiNdebele', messages: messages_de },
  { key: 'ne', label: 'नेपाली', messages: messages_de },
  { key: 'ng', label: 'Owambo', messages: messages_de },
  { key: 'nl', label: 'Nederlands', messages: messages_de },
  { key: 'nn', label: 'Norsk nynorsk', messages: messages_de },
  { key: 'no', label: 'Norsk', messages: messages_de },
  { key: 'nr', label: 'isiNdebele', messages: messages_de },
  { key: 'nv', label: 'Diné bizaad', messages: messages_de },
  { key: 'ny', label: 'chiCheŵa', messages: messages_de },
  { key: 'oc', label: 'Occitan', messages: messages_de },
  { key: 'oj', label: 'ᐊᓂᔑᓈᐯᒧᐎᓐ', messages: messages_de },
  { key: 'om', label: 'Afaan Oromoo', messages: messages_de },
  { key: 'or', label: 'ଓଡ଼ିଆ', messages: messages_de },
  { key: 'os', label: 'ирон æвзаг', messages: messages_de },
  { key: 'pa', label: 'ਪੰਜਾਬੀ', messages: messages_de },
  { key: 'pi', label: 'पाऴि', messages: messages_de },
  { key: 'ps', label: 'پښتو', messages: messages_de },
  { key: 'pt', label: 'Português', messages: messages_de },
  { key: 'qu', label: 'Runa Simi', messages: messages_de },
  { key: 'rm', label: 'Rumantsch Grischun', messages: messages_de },
  { key: 'rn', label: 'Ikirundi', messages: messages_de },
  { key: 'ro', label: 'Română', messages: messages_de },
  { key: 'rw', label: 'Ikinyarwanda', messages: messages_de },
  { key: 'sa', label: 'संस्कृतम्', messages: messages_de },
  { key: 'sc', label: 'Sardu', messages: messages_de },
  { key: 'sd', label: 'सिन्धी', messages: messages_de },
  { key: 'se', label: 'Davvisámegiella', messages: messages_de },
  { key: 'sg', label: 'Yângâ Tî Sängö', messages: messages_de },
  { key: 'si', label: 'සිංහල', messages: messages_de },
  { key: 'sk', label: 'Slovenčina', messages: messages_de },
  { key: 'sl', label: 'Slovenščina', messages: messages_de },
  { key: 'sm', label: "Gagana fa'a Samoa", messages: messages_de },
  { key: 'sn', label: 'chiShona', messages: messages_de },
  { key: 'so', label: 'Soomaaliga', messages: messages_de },
  { key: 'sq', label: 'Shqip', messages: messages_de },
  { key: 'sr', label: 'српски језик', messages: messages_de },
  { key: 'ss', label: 'SiSwati', messages: messages_de },
  { key: 'st', label: 'Sesotho', messages: messages_de },
  { key: 'su', label: 'Basa Sunda', messages: messages_de },
  { key: 'sv', label: 'Svenska', messages: messages_de },
  { key: 'sw', label: 'Kiswahili', messages: messages_de },
  { key: 'ta', label: 'தமிழ்', messages: messages_de },
  { key: 'te', label: 'తెలుగు', messages: messages_de },
  { key: 'tg', label: 'тоҷикӣ', messages: messages_de },
  { key: 'th', label: 'ไทย', messages: messages_de },
  { key: 'ti', label: 'ትግርኛ', messages: messages_de },
  { key: 'tk', label: 'Türkmençe', messages: messages_de },
  { key: 'tl', label: 'Wikang Tagalog', messages: messages_de },
  { key: 'tn', label: 'Setswana', messages: messages_de },
  { key: 'to', label: 'faka Tonga', messages: messages_de },
  { key: 'ts', label: 'Xitsonga', messages: messages_de },
  { key: 'tt', label: 'татар теле', messages: messages_de },
  { key: 'tw', label: 'Twi', messages: messages_de },
  { key: 'ty', label: 'Reo Tahiti', messages: messages_de },
  { key: 'ug', label: 'ئۇيغۇرچە', messages: messages_de },
  { key: 'ur', label: 'اردو', messages: messages_de },
  { key: 'uz', label: 'Ўзбек', messages: messages_de },
  { key: 've', label: 'Tshivenḓa', messages: messages_de },
  { key: 'vi', label: 'Tiếng Việt', messages: messages_de },
  { key: 'vo', label: 'Volapük', messages: messages_de },
  { key: 'wa', label: 'Walon', messages: messages_de },
  { key: 'wo', label: 'Wollof', messages: messages_de },
  { key: 'xh', label: 'isiXhosa', messages: messages_de },
  { key: 'yi', label: 'ייִדיש', messages: messages_de },
  { key: 'yo', label: 'Yorùbá', messages: messages_de },
  { key: 'za', label: 'Saɯ cueŋƅ', messages: messages_de },
  { key: 'zh', label: '中文', messages: messages_de },
  { key: 'zu', label: 'isiZulu', messages: messages_de },
] as Language[]

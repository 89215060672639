import styled from 'styled-components'
import React from 'react'
import { Link } from '@material-ui/core'
import { athenaUrl } from '../config'

const Image = styled.img<{ originalSize?: boolean }>`
  max-height: 80px;
`

export const AthenaLogo = () => {
  return (
    <Link href={athenaUrl} target="_blank">
      <Image alt="dampsoft-logo" src="athena_logo.png" />
    </Link>
  )
}

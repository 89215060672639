import styled from 'styled-components'
import React, { useContext } from 'react'
import { PracticeContext } from '../contexts/PracticeContext'
import { theme } from '../utils/theme'
import { DocumentFlowContext } from '../contexts/DocumentFlowContext'
import { Link } from '@material-ui/core'

const Image = styled.img<{ originalSize?: boolean; desktopOnly?: boolean }>`
  ${({ originalSize }) => (originalSize ? '' : `height: 40px;`)}
  margin: 10px 0;
  max-width: 100%;

  ${theme.breakpoints.down('sm')} {
    ${({ desktopOnly }) => (desktopOnly ? 'display: none;' : 'max-width: 90%;')}
  }
`

interface PracticeLogoProps {
  originalSize?: boolean
  desktopOnly?: boolean
}

export const PracticeLogo = (props: PracticeLogoProps) => {
  const { logo_url } = useContext(PracticeContext)
  const { stage, setStepIndex } = useContext(DocumentFlowContext)

  if (!logo_url) return <div />

  return (
    <Link href="#" onClick={() => stage === 'documentFlow' && setStepIndex(0)}>
      <Image alt="practice-logo" src={logo_url} {...props} />
    </Link>
  )
}

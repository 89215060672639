import React, { useContext } from 'react'
import { ActionButton } from './ActionButton'
import { LanguageContext } from '../contexts/LanguageContext'
import styled from 'styled-components'
import { theme } from '../utils/theme'
import { FormattedMessage } from 'react-intl'

const Container = styled.div`
  display: flex;
  margin-top: 10px;
  flex-direction: column;

  ${theme.breakpoints.down('sm')} {
    display: none;
  }
`

const Languages = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`

const LanguageInstructions = styled.p`
  margin-bottom: 20px;

  ${theme.breakpoints.down('sm')} {
    display: none;
  }
`

const ButtonContainer = styled.div`
  margin: 10px 10px 0 0;
`

export const LanguagePickerRow = () => {
  const { locale, setLocale, supportedLanguages } = useContext(LanguageContext)

  if (supportedLanguages.length <= 1) return <div />

  return (
    <Container>
      <LanguageInstructions>
        <FormattedMessage id="welcome.languageSelection" />
      </LanguageInstructions>
      <Languages>
        {supportedLanguages.map((language) => (
          <ButtonContainer key={language.key}>
            <ActionButton
              primary={language.key === locale}
              title={language.label}
              action={() => setLocale(language.key)}
              size="medium"
              disabled={!language.messages}
            />
          </ButtonContainer>
        ))}
      </Languages>
    </Container>
  )
}
